@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-thin-webfont.eot');
  src:
    url('fonts/Archia/archia-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-thin-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-thin-webfont.woff') format('woff'),
    url('fonts/Archia/archia-thin-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-light-webfont.eot');
  src:
    url('fonts/Archia/archia-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-light-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-light-webfont.woff') format('woff'),
    url('fonts/Archia/archia-light-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-regular-webfont.eot');
  src:
    url('fonts/Archia/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-regular-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-regular-webfont.woff') format('woff'),
    url('fonts/Archia/archia-regular-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-medium-webfont.eot');
  src:
    url('fonts/Archia/archia-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-medium-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-medium-webfont.woff') format('woff'),
    url('fonts/Archia/archia-medium-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-semibold-webfont.eot');
  src:
    url('fonts/Archia/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-semibold-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-semibold-webfont.woff') format('woff'),
    url('fonts/Archia/archia-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-bold-webfont.eot');
  src:
    url('fonts/Archia/archia-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-bold-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-bold-webfont.woff') format('woff'),
    url('fonts/Archia/archia-bold-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Color Pallete */

$blue: #007bff;
$blue-light: #007bff10;
$blue-hover: #007bff30;
$blue-light-opaque: #eff7ff;
$blue-mid: #007bff99;
$pblue: #4c75f2;
$pblue-light: #4c75f210;
$pblue-hover: #4c75f230;
$pblue-light-opaque: #eff7ff;
$pblue-mid: #4c75f299;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$cherry: #ff073a;
$cherry-mid: #ff073a99;
$cherry-light: #ff073a20;
$cherry-hover: #ff073a30;
$cherry-light-opaque: #ffe0e6;
$red: #dc3545;
$red-light: #dc354520;
$red-mid: #dc354599;
$orange: #fd7e14;
$orange-mid: #fd7e1499;
$orange-light: #fd7e1420;
$orange-hover: #fd7e1430;
$orange-light-opaque: #ffefe2;
$yellow: #ffc107;
$yellow-light: #ffc10720;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$yellow-light-opaque: #fff7e0;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-light-opaque: #f6f6f7;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$gray-dark: #343a40;
$gray-opaque: #f1f1f1;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$purple: #201aa2dd;
$purple-light: #201aa220;
$purple-hover: #201aa230;
$purple-mid: #201aa299;
$purple-light-opaque: #e3e2f3;
$pink: #fb5581;
$pink-light: #ffa8cb10;
$pink-hover: #ffa8cb30;
$pink-mid: #ffa8cb99;

@mixin navbarReveal($color) {
  $animation-name: unique-id() !global;

  animation-name: $animation-name;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  @keyframes #{$animation-name} {

    0% {
      background: transparent;
    }

    100% {
      background: $color;
    }
  }
}

@mixin levelColor($color) {
  .is-#{$color} {

    h1 { color: $color; }

    h4,
    h5 { color: #{$color}-mid; }
  }
}

body {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1 {
  font-family: 'archia';
  font-size: 24px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h2 {
  font-family: 'archia';
  font-size: 18px !important;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  font-family: 'archia';
  font-size: 14px !important;
  font-weight: 900;
  //text-transform: uppercase;
}

h4 {
  font-family: 'archia';
  font-size: 13px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h5 {
  font-family: 'archia';
  font-size: 12px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h6 {
  font-family: 'archia';
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.arrow-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $gray-mid;
  margin-left: 0.25rem;
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $gray-mid;
  margin-left: 0.25rem;
}

.Home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 5rem;
  margin-right: 5rem;

  .home-left,
  .home-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .home-left {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .home-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

.Resources {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 2rem;
  margin-right: 2rem;

  .resource-left,
  .resource-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .resource-left {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .resource-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .tableandcontrols{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  table {
    // position: relative;
    // width: 100%;
    // align-self: center;
    // font-family: 'archia';
    // text-transform: none;
    // border-spacing: 3px 2px;
    // border-collapse: separate;
    // // animation-name: fadeInOut;
    // // animation-duration: 2s;

    // thead {
    //   background: $purple-light;
    //   color: $gray-dark;
    //   text-align: center;
    //   font-size: 0.9rem;

    //   th {
    //     padding: 0.5rem;
    //     cursor: pointer;
    //     border-radius: 5px;
    //     transition: all 0.1s ease-in-out;
    //     z-index: 99;

    //     &.sticky {
    //       top: 4px;
    //       background: $gray-opaque;
    //     }

    //     &:hover {
    //       background: #ecedee;
    //     }

    //     .heading-content {
    //       display: flex;
    //       justify-content: space-between;
    //       position: relative;
    //       flex-direction: row;
    //       height: 0.9rem;
    //       align-items: center;

    //       abbr {
    //         text-align: right;
    //       }

    //       svg {
    //         width: 10px;
    //         margin: 0;
    //         right: 0;
    //         stroke-width: 4px;
    //         margin-top: 0.15rem;
    //         margin-left: 0.25rem;
    //         color: $gray-mid;
    //       }
    //     }
    //   }
    // }

    tbody {
      color: $gray;

      tr {
        transition: background 0.1s ease-in-out;
        // cursor: pointer;

        &:hover {
          background: $gray-hover !important;
        }

        &.divider {
          height: 0.5rem;
          background: #6c757d10 !important;
        }

        &.spacer {
          height: 0.5rem;
          background: #fff !important;
        }
      }

       tr:nth-child(even) {background-color: $purple-light;}

      td {
        // padding: 0.25rem;
        font-size: 0.7rem;
        border-radius: 5px;
        text-align: left;
        .deltas {
          margin-right: 0.25rem;
          font-size: 11px !important;

          svg {
            width: 9px;
            height: 9px;
            stroke-width: 3;
            vertical-align: -0.25px;
          }
        }
      }

      td:first-child {
        text-align: left;
        max-width: 7.5rem;
        word-wrap: break-word;
      }

      .state-last-update{
        background: transparent;
        transform: translateX(1rem);
        &:hover{
          background: #fff !important;
        }

        td{
          .last-update{
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            width: 100%;
          }
        }
      }

      .district-heading {
        transform: translateX(1rem);
        background: $gray-light;

        td {
          font-size: 0.75rem;
          font-weight: 900;
          padding: 0.45rem;
          color: $gray-dark;
          background: $gray-light;

          .heading-content {
            display: flex;
            justify-content: space-between;
            position: relative;
            flex-direction: row;
            height: 0.9rem;
            align-items: center;

            abbr {
              text-align: right;
            }

            svg {
              width: 10px;
              margin: 0;
              right: 0;
              stroke-width: 4px;
              margin-left: 0.25rem;
              margin-top: 0.15rem;
              color: $gray-mid;
            }
          }
        }
      }

      max-width: 10rem;

      .district {
        transform: translateX(1rem);
      }
    }

    .affected-count {
      position: absolute;
      margin: 0;
      top: -1rem;
      color: $gray-mid;
      right: 0;
    }

    .is-total {
      td {
        background: $gray-hover;
      }
    }

    .dropdown {
      position: absolute;
      left: -0.75rem;
      background: $gray-light;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      margin-top: 0.5rem;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      &:hover {
        background: $gray-hover;
      }

      svg {
        position: absolute;
        top: -0.35rem;
        left: 1px;
        width: 11px;
        stroke-width: 3;
      }
    }
  }


}

.filtersection {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 1rem;
  .filtertitle {
    align-self: center;
    color: $cherry;
  }
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;

}

.resourcefilters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .button {
    padding: 0.4rem;
  }
}

.resourcefilter {

  display: flex;
  flex-direction: column;
  // min-width: 30%;
  margin-left: 1rem;
  margin-right: 1rem;
  justify-content: space-around;
  align-items: center;
  .filterlabel {
    font-family: 'archia';
    font-size: 11px !important;
    font-weight: 600;
    text-transform: uppercase;
    color: $indigo;
  }

  select {
    width: 100%;
    // background-color: $purple-light-opaque;
    // border-radius: 10px;
  }

}

.pagination{
  display: flex;
  flex-direction: row;
  justify-content: center;
  .button {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-top: 0.7rem;
    height: 70%;
    width: 3rem;
    padding-left: 1.1rem;
    // text-align: center;
    justify-content: space-between;
    align-items: center;
    // margin-top
  }

  select{
    // height: 2.2 rem;
    // margin-top: 0.7rem;
    margin: 0.7rem 0.7rem 0.2rem 0.1rem;
  }

  input{
    // max-height: fit-content;
    // height: 0.5rem;
    width: 100px;
    margin: 0.7rem 0.7rem 0.2rem 0.7rem;
  }
}

.paginationbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 20px;
  font-family: 'Archia';
  font-size: 0.8rem;
  font-weight: 600;
  color: $purple;
  background: $purple-light;

  .snippet {
    align-self: center;
    width: calc(100% - 10rem);
    word-wrap: break-word;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
  }
}

.Navbar {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  justify-content: space-around;
  text-transform: uppercase;
  font-weight: 900;
  align-items: center;

  @include navbarReveal($gray-light);

  .navbar-left {
    margin-left: 2rem;
    flex: 1;

    a {
      display: inline-block;
      font-size: 14px;
      font-family: 'Archia';
      text-decoration: none;
      margin-right: 2rem;
      color: $gray-mid;

      span {
        display: inline-block;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: $gray;
        }
      }
    }
  }

  .focused {
    color: $gray;
  }

  img {
    width: 3rem;
    height: 3rem;
    margin-left: 5rem;
    cursor: pointer;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-self: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin-bottom: 1rem;

  h1,
  h6 {
    margin: 0;
  }

  h1 {
    color: $gray-dark;
  }

  .header-mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titles {
      h6 {
        margin-top: 0.25rem;
        color: $gray;
      }
    }

    & > * {
      align-self: center;
    }
  }
}

.last-update {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: right;
  margin-bottom: auto;
  margin-top: 0.25rem;

  h6 {
    color: $green-mid;
    font-weight: 900;
  }

  h3 {
    color: $green;
    font-weight: 600;
  }

  h3,
  h6 {
    margin: 0;
  }
}

.button {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border: 0;
  font-family: 'archia';
  text-transform: uppercase;
  background: $blue-light;
  color: $blue-mid;
  cursor: pointer;
  font-weight: 900;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  outline: none;
  font-size: 0.75rem;
  text-decoration: none;

  svg {
    width: 20px;
    stroke-width: 2px;
  }

  &.is-purple {
    color: $purple-mid;
    background: $purple-light;

    &:hover {
      background: $purple-hover;
    }
  }

  &.is-green {
    color: $green-mid;
    background: $green-light;

    &:hover {
      background: $green-hover;
    }
  }

  &:hover {
    background: $blue-hover;
  }

  & > * {
    align-self: center;
  }
}


.excel {
  color: #33a667;
  background: #33a66730;

  &:hover {
    background: #33a66750;
  }
}


#chart {
  z-index: 10;
}

.back-button {
  z-index: 11;
}

.map-default {
  stroke: none;
}

.map-hover {
  stroke: #ff073a;
  stroke-width: 2;
}

.MapExplorer {
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 30rem;

  .header {
    display: flex;
    flex-direction: column;
    color: $gray;
    margin-bottom: 0.1rem;
    align-self: flex-start;
    h1 {
      margin-bottom: 0.25rem;
    }
    
  }

  .svg-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    
    svg {
      align-self: center;

      text {
        text-transform: uppercase;
        text-align: right;
        font-family: 'archia';
        font-weight: 600;
        fill: $gray;
        font-size: 10px;
      }
    }
  }

  .back-button {
    position: absolute;
    right: 0;
    background: $orange-light;
    color: $orange;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $orange-hover;
    }
  }

  .meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 1rem;

    .district-last-update{
      position: absolute;
      right: 0;
      bottom: 0;
    }

    h2 {
      margin: 0;
      color: #e23028;
      word-wrap: break-word;
      font-weight: 900;
    }

    h4 {
      margin: 0;
      color: $gray-mid;
      word-wrap: break-word;
    }

    .district-confirmed {
      color: #e23028;
      position: absolute;
      top: 3rem;
      width: 5rem;
    }

    .unknown {
      position: absolute;
      top: 5rem;
      width: 5rem;
    }
  }

  .map-stats {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    position: relative;

    h1,
    h5,
    h6,
    h3 {
      margin: 0;
    }

    h3 {
      color: $gray-mid;
      font-weight: 900;
    }

    .stats {
      background: $cherry-light;
      position: relative;
      color: $cherry;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      margin-bottom: 0.5rem;
      flex: 1;
      padding: 0.25rem;

      .stats-bottom {
        display: flex;
        flex-direction: row;

        h6 {
          margin-top: auto;
          margin-bottom: 0.25rem;
        }
      }

      h1 {
        font-weight: 600;
      }

      &.is-green {
        background: $green-light;
        h5,
        h6 { color: $green-mid; }

        h1 { color: $green; }
      }

      &.is-blue {
        background: #cbe2fd;
        h5,
        h6 { color: #2965a8; }

        h1 { color: #014798; }
      }

      &.is-gray {
        background: $gray-light;
        h5,
        h6 { color: $gray-mid; }

        h1 { color: $gray; }
      }

      &.is-yellow {
        background: $warning;
        h5,
        h6 { color: $white; }

        h1 { color: $white; }
      }

      &.is-purple {
        background: hsl(167, 100%, 96%);
        padding-right: 1rem;
        h5,
        h6 {
          color: #62EACA;
          &.timestamp {
            margin: 0;
            font-weight: 900;
          }
        }

        h1 { color: #62EACA; }

        svg {
          position: absolute;
          right: 0.35rem;
          top: 0;
          stroke-width: 3;
          width: 12px;
          stroke: $purple-mid;
          cursor: pointer;
          transition: stroke 0.2s ease-in-out;
          &:hover {
            stroke: $purple;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.disabled {
  input.switch {
    background: $gray-light !important;
    border: $gray-light 2px solid !important;
    cursor: not-allowed !important;
  }

  input.switch::after {
    background: $gray-light !important;
  }
}

input.switch {
  position: relative;
  appearance: none;
  outline: none;
  margin: 0;
  cursor: pointer;
  width: 24px;
  height: 14px;
  background-color: #fff;
  border: 2px solid #d9dadc;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}

input.switch::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: $gray-mid;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
}

input.switch:checked {
  background-color: $gray-mid;
  transition: all 300ms ease-in-out;
}

input.switch:checked::after {
  left: 10px;
  background-color: $light;
  transition: all 300ms ease-in-out;
}

.floating-buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 0;
  z-index: 1;
  width: 3rem;

  button {
    border-radius: 50%;
    border: 0;
    padding: 0.75rem;
    background: $yellow-mid;
    color: $orange;
    cursor: pointer;
    margin: 0.25rem;
    display: flex;
    flex-direction: row;
    outline: none;
    transition: all 0.2s ease-in-out;
    backdrop-filter: saturate(180%) blur(2px);
    &:hover {
      background: #ffa500;
      svg {
        stroke: #ffa500;
      }
    }

    svg {
      align-self: center;
      stroke: $orange;
    }
  }

  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;

  a {
    font-family: 'archia';
    text-transform: uppercase;
    word-wrap: break-word;
    font-weight: 900;
    text-decoration: none;
    color: $blue;
    background: $blue-light;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: $blue-hover;
    }
  }

  h3 {
    margin-bottom: 0;
    color: $gray-dark;
    font-weight: 900;
  }
}

.select {
  display: flex;
  flex-direction: column;
  label {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 900;
    color: $gray-mid;
    margin-bottom: 0.25rem;
  }
}

select {
  width: 7rem;
  margin-right: 0.25rem;
  cursor: pointer;
}

.filters {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 1rem;
  background: #fff !important;
  z-index: 101;
  font-family: archia;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12),inset 0 -1px 0 0 #dadce0;
  justify-content: space-between;

  & > * {
    align-self: center;
  }

  .filters-left {
    display: flex;
    flex-direction: row;
  }
}

.pills {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  > button {
    border: 2px $yellow-light solid;
    background-color: $yellow-light;
    color: $orange;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    transition: background 0.25s ease-in-out;
    padding: 5px 15px;
    font-family: 'archia';
    cursor: pointer;
    margin: 0;
    outline: none;
    &:hover {
      background: $yellow-hover;
    }

    &.selected {
      background-color: $yellow-mid;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 0;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 0;
    }
  }
}

.legend {
  background: #fff;
  z-index: 101;
  font-family: archia;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    align-self: center;
  }

  .legend-left {
    display: flex;
    flex-direction: row;
  }

  select {
    margin-left: 1rem;
  }

  h5 {
    margin: 0;
    margin-right: 0.5rem;
    color: $gray;
    &.is-female {
      color: $pink;
    }

    &.is-male {
      color: $pblue;
    }

    &.is-local {
      color: $cherry;
    }

    &.is-imported {
      color: $purple;
    }
  }

  .circle {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: $gray-mid;
    margin-right: 0.25rem;
    &.is-female {
      background: $pink;
    }

    &.is-male {
      background: $pblue;
    }

    &.is-local {
      background: $cherry;
    }

    &.is-imported {
      background: $purple;
    }

    &.is-in {
      background: url('/flags/india.png');
      background-size: cover !important;
    }

    &.is-uk {
      background: url('/flags/uk.png');
      background-size: cover !important;
    }

    &.is-us {
      background: url('/flags/usa.png');
      background-size: cover !important;
    }

    &.is-th {
      background: url('/flags/thailand.png');
      background-size: cover !important;
    }

    &.is-ph {
      background: url('/flags/philippines.png');
      background-size: cover !important;
    }

    &.is-it {
      background: url('/flags/italy.png');
    background-size: cover !important;
    }

    &.is-ca {
      background: url('/flags/canada.png');
      background-size: cover !important;
    }

    &.is-id {
      background: url('/flags/indonesia.png');
      background-size: cover !important;
    }

    &.is-mm {
      background: url('/flags/myanmar.png');
      background-size: cover !important;
    }
  }
}

.updates-header {
  width: 30rem;
  align-self: center;
  color: $gray-dark;
  h2 {
    color: $pblue;
    font-weight: 900;
  }
}

.updates {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  .update {
    background: $gray-light;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $gray-hover;
    }

    h5, h4 {
      margin: 0;
    }

    h5 {
      color: $gray-mid;
      margin-bottom: 0.15rem;
    }

    h4 {
      color: $gray;
      font-weight: 600;
    }
  }

  .button {
    background: $pblue-light;
    height: 3rem;
    display: flex;
    flex-direction: row;
    color: $pblue;
    width: 13rem;
    & > * {
      align-self: center;
    }

    &:hover {
      background: $pblue-hover;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    flex-direction: row;
    display: flex;
    & > * {
      align-self: center;
    }
  }
}

@media(max-width: 400px){
  .PatientsDB .modal .modal-content .meta {
    height: 25rem !important;
  }
}

.cards-container {
  padding: 4rem;
  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      margin-bottom: 2em;
      flex: 0 1 calc(33% - 1em);
      box-shadow: rgba(#000, 0.2) 0 0 8px 0;
      &:hover {
        box-shadow: rgba(#000, 0.3) 0 0 8px 0;
      }
      height: 30rem;
        .charts-header {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          .chart-title {
            font-family: 'archia';
            font-size: 1.5rem !important;
            color: #6c757d;
            font-weight: 900;
            text-transform: uppercase;
            padding: 20px 0 20px 0;
          }

          .chart-content {
            height: 100%;
            width: 100%;
          }

          .chart-note {
           color: $gray;
           height: 50px;
           margin-top: 5px;
          }
        }
    }
  }
}

@media (max-width: 769px) {
  .TimeSeries,
  table,
  .header,
  .Level,
  .timeseries-header,
  .TimeSeries-Parent,
  .Links,
  .Minigraph,
  .Summary,
  .FAQ,
  .MapExplorer,
  .patients-summary
  .filters,
  .updates, .updates-header {
    width: calc(100%);
  }

  .Home {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .home-left,
    .home-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  body.modal-open {
    width: 100%;
  }

  .link,
  .faq {
    width: calc(100% - 2rem);
  }

  .Navbar {
    position: relative;

    a {
      font-size: 0.75rem !important;
      margin-right: 0.75rem !important;
    }

    img {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      margin-right: -1rem;
      cursor: pointer;
    }

    .navbar-menu {
      background: $gray-light-opaque;
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-color: $gray-hover;
      box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.12), inset 0 -1px 0 0 #dadce0;
      left: 0;
      padding: 0.5rem 0;
      position: absolute;
      right: 0;
      top: 4.5rem;
      z-index: 999;

      &.hidden {
        display: none;
      }

      a {
        display: block;
        padding: 0.75rem 1rem;
      }
    }

    .navbar-toggle {
      align-items: center;
      background: $gray-light;
      border-radius: 5px;
      border: 2px solid $gray-mid;
      color: $gray-mid;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-family: 'Archia';
      font-size: 14px;
      height: 1.25rem;
      justify-content: center;
      margin-right: 1rem;
      width: 1.25rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        border-color: $gray;
        color: $gray;
      }

      div {
        background: currentColor;
        margin: 1px 0;
        width: 0.75rem;
        height: 2px;
      }
    }
  }

  .timeseries {
    .svg-parent {
      width: 100%;
    }
  }

  .map-stats {
    .stats {
      .timestamp {
        width: 4.5rem;
      }
    }
  }

  .last-update {
    width: 10rem;
  }

  a.button {
    display: flex;
    flex: row;
    width: 10rem;
    justify-content: center;

    & > * {
      align-self: center;
    }

    svg {
      width: 38px;
    }
  }

  .Banner {
    height: 7rem;

    .snippet {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  table {
    td {
      max-width: 6.75rem;
    }
  }

  .cards-container {
    padding: 1rem;
    .cards {
      .card {
        flex: 0 1 calc(100%);
      }
    }
  }

  select {
    margin-left: 2px;
    margin-right: 2px;
  }

  &.nationality {
    margin-bottom: 0.5rem;
  }

  &.genders {
    margin-top: 0.5rem;
  }

  .Resources {
    table {
      position: relative;
      width: 40rem;
      align-self: center;
      font-family: 'archia';
      text-transform: none;
      border-spacing: 3px 2px;
      border-collapse: separate;
      // border: 0.1rem solid rgb(151, 150, 221);

      thead {
        background: $purple-light;
        color: $gray-dark;
        text-align: center;
        font-size: 0.7rem;

        th {
          padding: 0.5rem;
          cursor: pointer;
          border-radius: 5px;
          transition: all 0.1s ease-in-out;
          z-index: 99;

          &.sticky {
            top: 4px;
            background: $gray-opaque;
          }

          &:hover {
            background: #ecedee;
          }

          .heading-content {
            display: flex;
            justify-content: space-between;
            position: relative;
            flex-direction: row;
            height: 0.9rem;
            align-items: center;

            abbr {
              text-align: right;
            }

            svg {
              width: 10px;
              margin: 0;
              right: 0;
              stroke-width: 4px;
              margin-top: 0.15rem;
              margin-left: 0.25rem;
              color: $gray-mid;
            }
          }
        }
      }

      tbody {
        color: $gray;

        tr {
          transition: background 0.1s ease-in-out;
          // cursor: pointer;

          &:hover {
            background: $gray-hover !important;
          }

          &.divider {
            height: 0.5rem;
            background: #6c757d10 !important;
          }

          &.spacer {
            height: 0.5rem;
            background: #fff !important;
          }
        }

        tr:nth-child(even) {background-color: $purple-light;}

        td {
          // padding: 0.25rem;
          font-size: 0.6rem;
          border-radius: 5px;
          text-align: left;
          .deltas {
            margin-right: 0.25rem;
            font-size: 11px !important;

            svg {
              width: 9px;
              height: 9px;
              stroke-width: 3;
              vertical-align: -0.25px;
            }
          }
        }

        td:first-child {
          text-align: left;
          max-width: 7.5rem;
          word-wrap: break-word;
        }

        .state-last-update{
          background: transparent;
          transform: translateX(1rem);
          &:hover{
            background: #fff !important;
          }

          td{
            .last-update{
              text-align: left;
              display: flex;
              flex-direction: row;
              align-items: baseline;
              width: 100%;
            }
          }
        }

        .district-heading {
          transform: translateX(1rem);
          background: $gray-light;

          td {
            font-size: 0.75rem;
            font-weight: 900;
            padding: 0.45rem;
            color: $gray-dark;
            background: $gray-light;

            .heading-content {
              display: flex;
              justify-content: space-between;
              position: relative;
              flex-direction: row;
              height: 0.9rem;
              align-items: center;

              abbr {
                text-align: right;
              }

              svg {
                width: 10px;
                margin: 0;
                right: 0;
                stroke-width: 4px;
                margin-left: 0.25rem;
                margin-top: 0.15rem;
                color: $gray-mid;
              }
            }
          }
        }

        max-width: 10rem;

        .district {
          transform: translateX(1rem);
        }
      }

      .affected-count {
        position: absolute;
        margin: 0;
        top: -1rem;
        color: $gray-mid;
        right: 0;
      }

      .is-total {
        td {
          background: $gray-hover;
        }
      }

      .dropdown {
        position: absolute;
        left: -0.75rem;
        background: $gray-light;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        margin-top: 0.5rem;
        cursor: pointer;
        transition: background 0.2s ease-in-out;

        &:hover {
          background: $gray-hover;
        }

        svg {
          position: absolute;
          top: -0.35rem;
          left: 1px;
          width: 11px;
          stroke-width: 3;
        }
      }
    }


  }



  .resourcefilters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .resourcefilter {
    margin-bottom: 0.4rem;
  }

  .filtersection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.1rem;
    .filtertitle {
      align-self: center;
      text-align: center;
      color: $cherry;
    }
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-timing-function: ease-in;

  }

  .pagination{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .select{
      // height: max-content;
      margin-top: 0.4rem;
      margin-right: 0;
      // min-width: 9rem;
    }

    input{
      // min-width: 8rem;
      // height: max-content;
      margin: 0.1rem 0.1rem 0.1rem 0.1rem;
    }

    h5{
      margin-top: 0;
      margin-bottom: 0.1rem;
    }
  }

}

.iframe-container {
  width: 100%;
  min-height: 100vh;

  iframe {
    width: 100vw;
    height: 100vh;
    border: 0;
  }
}


/* Animation Support */

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

.tabFadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rotateDownRight {
  animation-name: rotateDownRight;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

.rotateRightDown {
  animation-name: rotateRightDown;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.height-22 {
	height: 22px;
}
.flex-home {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 }
.flex-home-child{
  flex: 1;
  flex-basis: 50%;
 }  

   
 .contentTable {
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width:100%;
  overflow-x:auto;
}

.contentTable thead tr {
  background-color: #014798;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  overflow-x:auto;
}

.contentTable th,
.contentTable td {
  padding: 12px 15px;
  overflow-x:auto;
}

.contentTable tbody tr {
  border-bottom: 1px solid #dddddd;
  overflow-x:auto;
}

.contentTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  overflow-x:auto;
}

.contentTable tbody tr:last-of-type {
  border-bottom: 2px solid #014798;
  overflow-x:auto;
}

.contentTable tbody tr.active-row {
  font-weight: bold;
  color: #014798;
  overflow-x:auto;
} 

hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;               
  background-color:#62EACA;
  height: 1px;
}

.MuiDivider-inset {
  margin-left: 0px;
}
.MuiTypography-gutterBottom {
  margin-bottom: 0.7em;
}

.btn {
  border: none;
  color: #62EACA;
  overflow: hidden;
  margin: 1rem;
  padding: 0;
  text-transform: uppercase;
}
.donateButton {
    background-color: #014798; 
    border: none;
    color: white;
     padding: 0.5rem;
    // text-align: center;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    display: block;
    font-size: 14px;
    margin: auto;
}
.donateButton:hover {
    background-color: #1060b4;
}
.donateImage {
  display: block;
  margin: auto;
  object-fit: cover;
}
.github-credits{
  line-height: 0.01;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  align-self: center;
  text-align: left;
  margin-bottom: auto;
  margin-top: 0.25rem;
  margin-left: 0.8rem;
}
.share-button{
  width: 8rem;
  height: 2rem;
  background: #ffffff;
  border-radius: 20px;
  border-style: solid;
  border-width: thin;
  border-color: #62EACA;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  transition: .3s linear;
  }
.share-button:hover{
  transform: scale(1.1);
}

.share-button span{
  padding: 0.1rem 0.1rem;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  color: #62EACA;
  text-align: center;
  z-index: 999;
  transition: .6s linear;
  border-radius: 20px;
}

.share-button:hover span{
  transform: translateX(-100%);
  transition-delay: .3s;
}

.share-button a{
  flex: 1;
  font-size: 26px;
  color: #2d3436;
  text-align: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.3s linear;
}

.share-button:hover a{
  opacity: 1;
  transform: translateX(0);
}

.share-button a:nth-of-type(1){
  transition-delay: 1s;
}

.share-button a:nth-of-type(2){
  transition-delay: 0.8s;
}

.share-button a:nth-of-type(3){
  transition-delay: 0.6s;
}

.share-button a:nth-of-type(4){
  transition-delay: 0.4s;
}

.fixed-footer{
  width: 100%;
  background: #59f3b9;
  position: relative; left: 0; bottom: 0;

}
.content{ 
  margin: auto;
  padding: 1rem 1rem;
  text-align:left;
  width:100%;
}

@import url('https://fonts.googleapis.com/css?family=Poppins');

.fixed-footer p{
    color: #004697;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    line-height: 1.4;
    overflow: hidden;
  }